/* CONTENT */
#content {
    position: relative;
    width: calc(100% - 320px);
    left: 320px;
    transition: .3s ease;

}

#sidebar.hide~#content {
    width: calc(100% - 100px);
    left: 100px;
}



@media screen and (max-width: 768px) {
    #content {
        width: calc(100% - 100px);
        left: 240px;
    }
}